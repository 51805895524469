import React, { useContext, useEffect, useState } from 'react';
import LoaderComponent from '../../../components/loader-component';
import { useParams } from 'react-router';
import GeneralError from '../../../components/general-error';
import { updatePatientPingAPI } from '../../../api/patient-ping/PatientPingAPI';
import { PatientPing } from '../interfaces';
import { Trans, useTranslation } from 'react-i18next';
import useLocationLanguage from '../../../hooks/useLocationLanguage';
import { STEP_COMPLETED } from '../../../utils/MixpanelEvents';
import { MixpanelContext } from '../../../contexts/MixpanelContext';

export type PatientPingSubmitWrapperProps = {
  goToPreviousStep: (stepName?: string, error?: string) => void;
  modulesData: any;
  patientPingData: PatientPing;
};

const PatientPingSubmitWrapper: React.FunctionComponent<PatientPingSubmitWrapperProps> = ({
  patientPingData,
  modulesData,
  goToPreviousStep,
}) => {
  const lang = useLocationLanguage();
  const { invitationID } = useParams<{ invitationID: string }>();
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation('handoff');
  const mixpanel = useContext(MixpanelContext);

  useEffect(() => {
    updatePatientPingAPI(invitationID, modulesData, lang)
      .then(() => {
        if (isError) {
          setIsError(true);
        } else {
          mixpanel.track(`PATIENT_PING_${STEP_COMPLETED}_SUCCESS`);
        }

        setIsLoading(false);
      })
      .catch(error => {
        const message = error.message;
        if (error.status && error.status === 400 && message && message.toLowerCase().indexOf('payment message') > -1) {
          setIsLoading(false);
          goToPreviousStep('', message);
        } else {
          setIsError(true);
        }
      })
      .finally(() => isLoading && setIsLoading(false));
  }, []);

  if (isLoading) {
    return <LoaderComponent className='loader-block-center' />;
  }

  if (isError) {
    return <GeneralError goToPreviousStep={goToPreviousStep} isFromPXB={true} />;
  }

  return (
    <div className='main-request-form main-request-successfull'>
      <h2 className='h2 mb16'>
        <span className='icon-success mb12'></span>
        <div>{t('form_final.title')}</div>
      </h2>
      <div className='text-form'>
        <p className='mb32'>{t('form_final.text-care')}</p>
        <hr />
        <div className='mb32'>
          <h2 className='mb16'>{t('form_final.What Happens Next')}</h2>
          <ol>
            <li>{t('form_final.point1')}</li>
            <li>{t('form_final.point2')}</li>
            <li>{t('form_final.point3')}</li>
          </ol>
        </div>
        <hr />
        <h2 className='mb16'>{t('form_final.Contact us')}</h2>
        <p className='mb24'>
          <Trans
            ns='handoff'
            i18nKey='form_final.text-info'
            values={{
              email: 'support@medmo.com',
              phone: patientPingData.AgentPhone,
            }}
            components={{ a: <a /> }}
          />
        </p>
      </div>
    </div>
  );
};

export default PatientPingSubmitWrapper;
